<template>
  <div>
    <a-breadcrumb style="margin-bottom: 10px">
      <a-breadcrumb-item> 位置:</a-breadcrumb-item>
      <a-breadcrumb-item><a @click="returnNews">新闻列表</a></a-breadcrumb-item>
      <a-breadcrumb-item><a href="">修改新闻</a></a-breadcrumb-item>
    </a-breadcrumb>
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="标题">
        <a-input style="width: 350px" v-model="form.title" />
      </a-form-model-item>

      <a-form-model-item label="封面">
        <upload-widget v-model="form.cover"></upload-widget>
      </a-form-model-item>
      <a-form-model-item label="所属栏目">
        <a-cascader :options="sections" :defaultValue="defaultValueSection" style="width: 250px" :popup-placement="topLeft" placeholder="请选择栏目" @change="onChange" expand-trigger="hover" />
      </a-form-model-item>
      <a-form-model-item label="内容">
        <div style="border: 1px solid #ccc">
          <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
          <Editor style="height: 500px; overflow-y: hidden" v-model="form.content" :defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated" />
        </div>
      </a-form-model-item>
      <a-form-model-item label="链接">
        <a-input style="width: 350px" v-model="form.link" />
      </a-form-model-item>
      <a-form-model-item label="上传附件:">
        <upload-file :max-upload-num="3" v-model="fileInfos" :file-list="fileList" @change="upload"></upload-file>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 2 }">
        <a-button type="primary" @click="onSubmit"> 提交 </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import UploadFile from '../../../components/UploadFile.vue';
export default {
  components: { UploadFile ,Editor, Toolbar},
  data() {
    return {
      editor: null,
      toolbarConfig: {},
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF: {
          uploadImage: {
            server: 'https://www.1daoyun.com/1daoyun/upload/upload',
            customInsert: function (res, insertFn) {
              console.log('res:', res);
              //insertFn(url, alt, href)
            },
            base64LimitSize: 1000000 * 1024, // 1g 以下插入 base64
            onFailed(file, res) {
								alert(res.message)
								console.log('onFailed', file, res)
							},
          },
        },
      },
      mode: 'default', // or 'simple'
      id: -1, //修改文章编号
      topLeft: 'topLeft',
      labelCol: { span: 2 },
      wrapperCol: { span: 18 },
      defaultValueSection: ['1', '1470394245499195392'],
      sections: [],
      fileList: [],
      // 文章分类数据
      // sections: [],
      fileInfos: [],
      // 文章表格数据
      form: {
        id: -1,
        title: '',
        cover: '',
        sectionId: 0,
        content: '',
        link: '',
        accessoryList: [],
      },
    };
  },
  created() {
    this.id = this.$route.params.id; //修改文章编号
    this.form.id = this.$route.params.id;
    this.loadList();
  },
  beforeDestroy() {
      const editor = this.editor;
      if (editor == null) return;
      editor.destroy(); // 组件销毁时，及时销毁编辑器
    },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    /**读取文章信息 */
    async loadList() {
      let result = await this.axios.get(`/news/find/${this.id}`);
      this.form = result.data.data;
      this.form.accessoryList = this.form.accessories;
      for (let f of this.form.accessories) {
        this.fileInfos.push({
          uid: f.id,
          name: f.fileName,
          status: 'done',
          url: f.path,
          // thumbUrl: this.cdn + fl,
        });
      }
      let section = await this.axios({
        method: 'get',
        url: `section/getAll`,
      });
      for (let s of section.data.data) {
        if (s.parentId == '0') {
          let obj = {};
          obj.value = s.id;
          obj.label = s.title;
          obj.children = [];
          for (let o of section.data.data) {
            if (o.parentId == obj.value) {
              let obj2 = {};
              obj2.value = o.id;
              obj2.label = o.title;
              obj.children.push(obj2);
            }
          }
          this.sections.push(obj);
        }
      }

      for (let s of this.sections) {
        let children = s.children;
        for (let c of children) {
          if (c.value == this.form.sectionId) {
            this.defaultValueSection[1] = c.value;
            this.defaultValueSection[0] = s.value;
          }
        }
      }
    },
    /**所属栏目 */
    onChange(value) {
      this.form.sectionId = value[1];
    },
    /**返回 */
    returnNews() {
      this.$router.push({
        name: 'newsList',
      });
    },
    /**上传 */
    upload(data) {
      this.form.accessoryList = data;
    },
    
    /**提交修改 */
    async onSubmit() {
      //提交文章修改信息
      let result = await this.axios.post('/news/update', this.form);
      //code的值为200代表修改成功
      if (result.data.code == 200) {
        this.$message.success('添加成功');
        this.fileList = [];
        //执行成功后返回列表页面
        this.$router.push({
          name: 'newsList',
        });
      } else {
        this.$message.error();
        ('添加失败');
      }
    },
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>
